<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio intervento</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Img modal, triggered with click on ticket attachment -->
                <Transition name="fade-transition">
                    <div v-show="showImgModal" class="attachment_modal">
                        <div class="customers_container">
                            <img :src="selectedImage" alt="Allegato ticket" />
                        </div>
                        <div @click="closeAttachmentModal()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>
                <div class="intervento">
                    <div class="field">
                        <div class="title">Cliente</div>
                        <div class="value">
                            {{ setShortCustomer(intervento.customers_company) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Progetto</div>
                        <div class="value">
                            {{ intervento.projects_name ? intervento.projects_name : "-" }}
                        </div>
                    </div>
                    <div v-if="intervento.tickets_subject" class="field">
                        <div class="title">Ticket</div>
                        <div class="value">
                            {{ intervento.tickets_subject ? intervento.tickets_subject : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data</div>
                        <div class="value">
                            {{ dateFormat(intervento.tickets_reports_date) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Orario</div>
                        <div class="value">
                            {{ intervento.tickets_reports_start_time + " - " + intervento.tickets_reports_end_time }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Ore da fatturare</div>
                        <div class="value">
                            {{ intervento.tickets_reports_billable_hours }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Stato lavori</div>
                        <div class="badge" :class="statusIntervento(intervento.tickets_reports_stato_lavori_chiudi_ticket)">
                            {{ intervento.tickets_reports_stato_lavori_value }}
                        </div>
                    </div>
                    <div class="field_note description">
                        <div class="title">Tecnici</div>
                        <div v-for="(tecnico, index) in intervento.tickets_reports_tecnici" :key="index" class="value">
                            {{ tecnico }}
                        </div>
                    </div>
                    <div v-if="intervento.tickets_reports_attachments" class="field_allegati">
                        <div class="title">Allegati</div>
                        <div class="allegati_container">
                            <img
                                v-for="(image, index) in JSON.parse(intervento.tickets_reports_attachments)"
                                :key="index"
                                :src="setImageUrl(image)"
                                alt=""
                                @click="openImgDetail(image)"
                            />
                        </div>
                    </div>
                    <div class="field_note description">
                        <div class="title">Descrizione</div>
                        <div class="value">
                            {{ parseHtmlEnteties(intervento.tickets_reports_description) }}
                        </div>
                    </div>
                    <div v-if="intervento.tickets_reports_used_equipments" class="field_note description">
                        <div class="title">Materiali utilizzati</div>
                        <div class="value">
                            {{ parseHtmlEnteties(intervento.tickets_reports_used_equipments) }}
                        </div>
                    </div>
                    <div class="field_note description">
                        <div class="title rapportino_container">
                            <a :href="scaricaRapportino(intervento.tickets_reports_id)" @click="fakeDownload()" class="link_rapportino" download>
                                Scarica PDF intervento
                            </a>
                        </div>
                    </div>
                    <div class="field_note description">
                        <div class="button_foto">
                            <!-- Foto da caricare -->
                            <div class="title">Foto da caricare</div>
                            <div class="value container_foto_da_caricare">
                                <div v-if="fotoDaCaricare.length != 0" class="field">
                                    <div class="foto_container">
                                        <div v-for="(foto, index) in fotoDaCaricare" :key="index" class="single_foto">
                                            <ion-thumbnail>
                                                <img :src="`data:image/png;base64,${foto.data}`" />
                                            </ion-thumbnail>
                                            <div @click="deletePhoto(foto.name)" class="remove_photo">Rimuovi</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>Nessuna foto selezionata</div>
                            </div>
                            <button v-if="fotoDaCaricare.length == 0" type="button" class="btn_foto" @click="openModalPhotos()">
                                Seleziona foto
                            </button>
                            <button v-else type="button" class="btn_foto_da_caricare" @click="saveSelectedPhotos()">
                                Salva foto
                            </button>
                        </div>
                        <div class="buttons_firme">
                            <div class="actions_firme">
                                <button
                                    v-if="!intervento.tickets_reports_firma_cliente_b64"
                                    type="button"
                                    class="btn_firma"
                                    :disabled="customerHasSigned"
                                    :class="{ btn_signed: customerHasSigned }"
                                    @click="openSignature('cliente')"
                                >
                                    Firma cliente
                                </button>
                                <button
                                    v-if="!intervento.tickets_reports_firma_operatore_b64"
                                    type="button"
                                    class="btn_firma"
                                    :disabled="technicianHasSigned"
                                    :class="{ btn_signed: technicianHasSigned }"
                                    @click="openSignature('tecnico')"
                                >
                                    Firma tecnico
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="intervento.tickets_reports_ticket_id" ref="ticketDom" class="intervento" style="margin-top: 32px;">
                    <div class="title_card" @click="showTicketDetail = !showTicketDetail">Dettaglio ticket #{{ intervento.tickets_reports_ticket_id }}</div>
                    <div v-if="showTicketDetail" class="info_ticket">
                        <div class="field">
                            <div class="title">Titolo</div>
                            <div class="value">
                                {{ intervento.tickets_subject ? intervento.tickets_subject : "-" }}
                            </div>
                        </div>
                        <div class="field">
                            <div class="title">Data apertura</div>
                            <div class="value">
                                {{ intervento.tickets_creation_date ? dateFormat(intervento.tickets_creation_date) : "-" }}
                            </div>
                        </div>
                        <div v-if="intervento.tickets_attachments" class="field_allegati">
                            <div class="title">Allegati</div>
                            <div class="allegati_container">
                                <img
                                    v-for="(image, index) in JSON.parse(intervento.tickets_attachments)"
                                    :key="index"
                                    :src="setImageUrl(image)"
                                    alt=""
                                    @click="openImgDetail(image)"
                                />
                            </div>
                        </div>
                        <div class="field_note">
                            <div class="title">Descrizione</div>
                            <div class="value">
                                {{ parseHtmlEnteties(intervento.tickets_message) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonThumbnail,
    modalController,
} from "@ionic/vue";

import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { arrowBackOutline } from "ionicons/icons";

import moment from "moment";
import autoAnimate from "@formkit/auto-animate";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";
import Loading from "@/custom/services/loading";

import interventiAPI from "@/services/interventi";
import interventiCustomAPI from "@/custom/services/interventi";

import TicketDetail from "@/components/TicketDetail.vue";
import ImagesModal from "@/components/ImagesModal";
import ModalFirmaInterventoDetail from "@/custom/ModalFirmaInterventoDetail";

export default defineComponent({
    name: "InterventoDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        IonThumbnail,
    },
    setup(props, context) {
        const route = useRoute();
        const id = route.params.id; //intervento_id by route params
        const router = useRouter();
        const intervento = ref([]);

        //console.log(props.data);
        intervento.value = { ...props.data };

        const ticketDom = ref();
        const showTicketDetail = ref(false);

        const customerHasSigned = ref(false);
        const technicianHasSigned = ref(false);
        const fotoCaricate = ref(false);
        const interventoModificato = ref(false);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        async function closeModal() {
            /* console.log("asd");
            modalController.dismiss(); */
            if (interventoModificato.value) {
                await modalController.dismiss({ intervento_modificato: true });
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Controllo se intervento firmato/foto allegato per disabilitare nuovi button
         */
        function checkIntervento() {
            if (intervento.value.tickets_reports_firma_cliente_b64) {
                customerHasSigned.value = true;
            }
            if (intervento.value.tickets_reports_firma_operatore_b64) {
                technicianHasSigned.value = true;
            }
            if (intervento.value.tickets_reports_attachments) {
                fotoCaricate.value = true;
            }
        }

        /**
         * ! Open modal to sign
         */
        async function openSignature(signatureType) {
            const modal = await modalController.create({
                component: ModalFirmaInterventoDetail,
                componentProps: {
                    data: signatureType,
                    firma:
                        signatureType === "tecnico" ? intervento.value.tickets_reports_firma_operatore_b64 : intervento.value.tickets_reports_firma_cliente_b64,
                    intervento_id: intervento.value.tickets_reports_id,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail.data);
                if (detail.data) {
                    //Cliente e/o tecnico hanno firmato, cambia classe al button
                    if (detail.data.firma && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            //intervento.firma_tecnico = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                technicianHasSigned.value = true;
                            }
                        } else {
                            //intervento.firma_cliente = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                customerHasSigned.value = true;
                            }
                        }
                        interventoModificato.value = true;
                    }
                    //Firma cliente e/o tecnico è stata pulita e chiusa la modale, devo togliere la firma da intervento
                    if (detail.data.da_cancellare === true && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            //intervento.firma_tecnico = "";
                            technicianHasSigned.value = false;
                        } else {
                            //intervento.firma_cliente = "";
                            customerHasSigned.value = false;
                        }
                    }
                }
            });
            return modal.present();
        }

        /**
         * ! Gestione foto intervento
         */
        const fotoDaCaricare = ref([]);
        async function openModalPhotos() {
            const modal = await modalController.create({
                component: ImagesModal, //Component to render inside ionic modal
                componentProps: {
                    images: fotoDaCaricare.value,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    const foto = detail.data;
                    if (foto) {
                        foto.forEach((singleFoto) => {
                            //console.log(singleFoto);
                            fotoDaCaricare.value.push(singleFoto);
                        });
                    }
                }
            });
            return modal.present();
        }

        /**
         * ! Close modal after form data submit
         */

        const isSubmit = ref(true);
        async function saveSelectedPhotos() {
            isSubmit.value = true;
            Loading.simpleLoader("Salvataggio in corso", 10000, true);

            const data = new FormData();

            fotoDaCaricare.value.forEach((foto) => {
                //console.log(foto.data);
                data.append("tickets_reports_attachments[]", foto.data);
            });

            try {
                const response = await interventiCustomAPI.addInterventoPhotos(intervento.value.tickets_reports_id, data);
                if (response.data.status === 8) {
                    isSubmit.value = false;
                    openToast(response.data.message, "toast_danger");
                } else {
                    //Loading.dismissLoader();
                    isSubmit.value = false;
                    interventoModificato.value = true;
                    closeModal();
                    openToast("Foto caricate con successo", "toast_success");
                }
            } catch (error) {
                Loading.dismissLoader();
                isSubmit.value = false;
                openToast("Errore durante la creazione dell'intervento", "toast_danger");
            } finally {
                Loading.dismissLoader();
                setTimeout(() => {
                    isSubmit.value = false;
                }, 1500);
            }
        }

        /**
         * ! Remove photo from the fotoDaCaricare reference data array
         */
        function deletePhoto(photo) {
            //console.log(photo);
            fotoDaCaricare.value = fotoDaCaricare.value.filter((foto) => foto.name !== photo);
        }

        /**
         * ! Limit customers to 35 charactes
         */
        function setShortCustomer(customer) {
            if (!customer) {
                return "-";
            } else {
                const trimmedString = customer.length > 35 ? customer.substring(0, 32) + "..." : customer;
                return trimmedString;
            }
        }

        /**
         * ! Estrae dati del ticket per apertura dettaglio
         */
        async function openTicketDetail(intervento) {
            if (intervento.tickets_reports_ticket_id) {
                //console.log(intervento.tickets_reports_ticket_id);
                const modal = await modalController.create({
                    component: TicketDetail,
                    componentProps: {
                        data: intervento,
                        ticket_id: intervento.tickets_reports_ticket_id,
                    },
                });
                modal.onDidDismiss().then((detail) => {
                    /* if (detail.data != undefined) {
          richieste.value = [detail.data, ...richieste.value];
          openToast("Nuova richiesta inserita", "toast_success");
        } */
                });
                return modal.present();
            }
        }

        /**
         * Remove html tag from ticket description
         */
        function parseHtmlEnteties(str) {
            if (str) {
                const strippedString = str.replace(/(<([^>]+)>)/gi, "");
                return strippedString.replace(/&#([0-9]{1,3});/gi, function(match, numStr) {
                    const num = parseInt(numStr, 10); // read num as normal number
                    return String.fromCharCode(num);
                });
            } else {
                return "-";
            }
        }

        //Set correct background for intervento status
        const statusIntervento = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == "1") {
                    //lavoro completato
                    className = "badge_low";
                } else {
                    //lavoro non completato
                    className = "badge_high";
                }
                return className;
            };
        });

        /**
         * Open fake modal to view selected ticket attachment
         */
        const selectedImage = ref(null);
        const showImgModal = ref(false);

        function openImgDetail(image) {
            showImgModal.value = true;
            selectedImage.value = `${process.env.VUE_APP_BASE_URL}/uploads/${image.path_local}`;
        }

        function closeAttachmentModal() {
            showImgModal.value = false;
        }

        function setImageUrl(img) {
            if (img) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${img.path_local}`;
            }
        }

        function scaricaRapportino(interventoId) {
            return `${process.env.VUE_APP_BASE_URL}tickets-report/main/getreportpdf/${interventoId}`;
        }

        function fakeDownload() {
            openToast("Download in corso...", "toast_info");
        }

        onMounted(() => {
            checkIntervento();

            if (intervento.value && intervento.value.tickets_subject) {
                autoAnimate(ticketDom.value);
            }
        });

        return {
            id,
            dateFormat,
            intervento,
            arrowBackOutline,
            closeModal,
            parseHtmlEnteties,
            setShortCustomer,
            statusIntervento,
            //Attachment modal
            showImgModal,
            selectedImage,
            openImgDetail,
            closeAttachmentModal,
            setImageUrl,
            //Open PDF rapportino
            scaricaRapportino,
            fakeDownload,
            //Ticket detail
            openTicketDetail,
            ticketDom,
            showTicketDetail,
            //Button per firme e caricamento foto
            customerHasSigned,
            technicianHasSigned,
            fotoCaricate,
            openSignature,
            //Caricamento foto
            openModalPhotos,
            fotoDaCaricare,
            saveSelectedPhotos,
            deletePhoto,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new intervento btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

.intervento .title_card {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 12px 6px;
    font-weight: 600;
    color: #475569;
}
.intervento .info_ticket {
    width: 100%;
}
.intervento {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.intervento .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 6px;
    /*padding: 12px 6px;
    margin-bottom: 10px;*/
    border-bottom: 1px solid #e2e8f0;
}
.intervento .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}
.intervento .field_note.description {
    border-bottom: 1px solid #e2e8f0;
}
.intervento .field_note.description:last-of-type {
    border-bottom: none;
}
.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field .ticket_title {
    text-transform: uppercase;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}

.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge {
    font-size: 10px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge_close,
.badge_low {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
    font-weight: bold;
}
.badge_standy,
.badge_high {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-weight: bold;
}
.badge_working,
.badge_medium {
    background-color: rgb(255 237 213);
    color: rgb(249 115 22);
    font-weight: bold;
}
.badge_waiting_answer {
    background-color: rgb(237 233 254);
    color: rgb(139 92 246);
    font-weight: bold;
}
.badge_open {
    background-color: rgb(207 250 254);
    color: rgb(6 182 212);
    font-weight: bold;
}
.badge_canceled {
    background-color: rgb(243 244 246);
    color: rgb(31 41 55);
    font-weight: bold;
}

.nuovo_intervento {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.intervento_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

/* Elenco interventi */
.interventi_container {
    margin-top: 16px;
}
.interventi_container .interventi_label {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #2a2a2a;
}
.intervento {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.intervento .date {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.intervento .status {
    font-size: 14px;
}

ion-button {
    --color: #ffffff;
}
/** Allegati  */
.allegati_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}
.allegati_container img {
    width: 200px;
    margin-right: 16px;
}
.intervento .field_allegati {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
    border-bottom: 1px solid #e2e8f0;
}
.field_allegati .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_allegati .title {
    margin-bottom: 8px;
}
.field_allegati .value {
    font-size: 14px;
}

/** Modal selected attachment */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.attachment_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 16px;
}

.title.rapportino_container {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    font-size: 16px;
}
a.link_rapportino {
    color: #086fa3;
    text-decoration: none;
}

.actions_firme {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;
}
.btn_firma {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.btn_firma.btn_signed {
    background-color: #086fa3;
    color: #ffffff;
    transition: all 0.15s ease-in;
}

.btn_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: #086fa3;
    color: #ffffff;
    margin-bottom: 16px;
}
.btn_foto_da_caricare {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: #1e8011;
    color: #ffffff;
    margin-bottom: 16px;
}

.container_foto_da_caricare {
    margin-bottom: 12px;
}

.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
}
.single_foto {
    margin-right: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
}
.intervento .container_foto_da_caricare .field {
    border-bottom: none;
}
</style>
